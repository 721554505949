import React, { CSSProperties } from "react";
import {
  AppTheme,
  //DefaultTheme,
  //ThemedStyledComponentsModule,
} from "styled-components";
import { blackMamba } from "styles/theme";
import sympire_defoult_bg_img from "assets/images/bg_lobbynew.svg";
//import blue_bg_img from "assets/images/bg_blue.svg";

// interface IPalette {
//   main: string;
//   contrastText: string;
// }

export const getThemePalette = (name: string): AppTheme => {
  switch (name) {
    case "blog_light_theme":
      return { ...blogLightTheme, ...playerDarkTheme };
    case "blog_dark_theme":
      return { ...blogDarkTheme, ...playerDarkTheme };
    default:
      return { ...blackMamba, ...blogLightTheme, ...playerDarkTheme };
  }
};

export const blogLightTheme: AppTheme = {
  blog: {
    name: "blog_light_theme",
    backgroundColor: "rgba(255, 255, 255, 0.94)",
    backgroundImage: `url(${sympire_defoult_bg_img})`,
    borderRadius: "8px",
    borderColor: "rgb(23 191 131 / 48%)",
    textColor: "#131313",
    padding: "0.5rem",
    filter: "grayscale(1) brightness(2.5)",
    header: {
      width: "850px",
      height: "52px",
      backgroundColor: "rgba(19, 19, 19, 0.8)",
      borderRadius: "2px",
      borderColor: "#398a6e",
      boxShadow: "0 2px 12px 0 rgba(73, 189, 149, 0.5)",
      labelColor: "#9daab5",
      textColor: "#131313",
      separatorColor: "#d3d3d3",
      padding: "0 16px",
      gap: 0, //the gap between general panels
    },
    content: {
      width: "850px",
      backgroundColor: "#fbfbfb",
      borderRadius: 0,
      borderColor: "unset",
      boxShadow: "none",
      fontFamily: '"Roboto", sans-serif',
      fontSize: "18px",
      color: "#131313",
      padding: "32px 8px 32px 80px",
      gap: "24px",
      header: {
        fontFamily: '"Outfit", system-ui',
        fontSize: "34px",
        fontWeight: 600,
        color: "#131313",
      },
      title: {
        fontFamily: '"Outfit", system-ui',
        fontSize: "22px",
        fontWeight: 500,
        color: "#131313",
      },
      playerAvatar: {
        borderColor: "#d3d3d3",
      },
      exam: {
        titleColor: "#131313",
        color: "#131313",
        checkbox: {
          width: "16px",
          height: "16px",
          backgroundColor: "#fff",
          hoverBackgroundColor: "#fff",
          activeBackgroundColor: "#fff",
          disabledBackgroundColor: "#fff",
          borderColor: "#898989",
          hoverBorderColor: "#49bd95",
          activeBorderColor: "#49bd95",
          disabledBorderColor: "#d3d3d3",
          borderRadius: "4px",
          color: "#131313",
          //disabledColor: "#363636",
        },
        input: {
          width: "100%",
          height: "80px",
          backgroundColor: "#fff",
          hoverBackgroundColor: "#fff",
          activeBackgroundColor: "#fff",
          disabledBackgroundColor: "#d3d3d3",
          borderColor: "#9daab5", //"#898989", //"#9daab5"
          hoverBorderColor: "#898989",
          activeBorderColor: "#898989t",
          disabledBorderColor: "#2d6854",
          borderRadius: "8px",
          color: "#131313",
          disabledColor: "#d3d3d3",
        },
        submitButton: {
          width: "80px",
          height: "34px",
          backgroundColor: "#fff",
          hoverBackgroundColor: "#fff",
          activeBackgroundColor: "#fff",
          disabledBackgroundColor: "#f6f7f9",
          borderColor: "#398a6e",
          hoverBorderColor: "#49bd95",
          activeBorderColor: "#2d6854",
          disabledBorderColor: "#d3d3d3",
          borderRadius: "8px",
          color: "#398a6e",
          hoverColor: "#49bd95",
          activeColor: "#2d6854",
          disabledColor: "#d3d3d3",
        },
      },
    },
    arsenal: {
      container: {
        maxWidth: "202px",
        minWidth: "149px",
        backgroundColor: "#e6e6e6",
        borderRadius: "2px",
        borderColor: "transparent",
        color: "#131313",
      },
      item: {
        width: "100%",
        height: "68px",
        backgroundColor: "#fff",
        activeBackgroundColor: "rgba(73, 189, 149, 0.2)",
        disabledBackgroundColor: "transparent",
        borderRadius: "2px",
        borderColor: "#898989",
        hoverBorderColor: "#131313",
        activeBorderColor: "#49bd95",
        disabledBorderColor: "#898989",
        boxShadow: "0 0 0 0 rgba(99, 99, 99, 0)",
        activeBoxShadow: "0 0 0 0 rgba(99, 99, 99, 0)",
        hoverBoxShadow: "0 0 10px 0 rgba(99, 99, 99, 0.8)",
        color: "#4d4d4d",
        fontSize: "14px",
        fontFamily: `"Roboto Mono", monospace`,
      },
    },

    navbar: {
      container: {
        backgroundColor: "unset",
        borderRadius: 0,
        borderColor: "none",
      },
      navItem: {
        width: "34px",
        height: "34px",
        backgroundColor: "#fff",
        hoverBackgroundColor: "#fff",
        activeBackgroundColor: "#49bd95",
        disabledBackgroundColor: "#fbfbfb",
        examBackgroundColor: "#2a84ff",
        borderColor: "#4d4d4d",
        hoverBorderColor: "#4d4d4d",
        activeBorderColor: "#49bd95",
        disabledBorderColor: "#d3d3d3",
        boxShadow: "0 0 0 0 rgba(137, 137, 137, 0)",
        hoverBoxShadow: "0 0 8px 0 rgba(137, 137, 137, 0.8)",
        activeBoxShadow:
          "0 0 8px 0 rgba(73, 189, 149, 0.6), 0 0 12px 0 rgba(255, 255, 255, 0.2)",
        color: "#4d4d4d",
        hoverColor: "#4d4d4d",
        activeColor: "#fff",
        disabledColor: "#d3d3d3",
        labelColor: "#898989",
      },
      arrow: {
        width: "34px",
        height: "34px",
        backgroundColor: "#fff",
        hoverBackgroundColor: "#fff",
        activeBackgroundColor: "#131313",
        disabledBackgroundColor: "#fbfbfb",
        borderColor: "#398a6e",
        hoverBorderColor: "#49bd95",
        activeBorderColor: "#49bd95",
        disabledBorderColor: "#d3d3d399",
        color: "#398a6e",
        hoverColor: "#49bd95",
        activeColor: "#49bd95",
        disabledColor: "#d3d3d399",
        hoverBoxShadow:
          "0 0 12px 0 rgba(73, 189, 149, 0.8), 0 0 18px 0 rgba(255, 255, 255, 0.2)",
      },
    },

    networkMap: {
      backgroundColor: "#fff",
      borderColor: "#d3d3d3",
      borderRadius: "12px",
      boxShadow: "0 0 5px 2px rgba(49, 49, 64, 0.05)",
      titleColor: "#363636",
      color: "#363636",
    },

    popup: {
      backgroundColor: "#ffffff",
      borderColor: "#d3d3d3",
      borderRadius: "2px",
      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.16)",
      titleColor: "#131313",
      color: "#131313",
    },

    link: {
      color: "#2a84ffbd",
      hoverColor: "#2a84ff",
      activeColor: "#2a84ff",
      disabledColor: "#d3d3d3",
    },

    tooltip: {
      backgroundColor: "rgb(255 255 255 / 41%)",
      borderColor: "#131313",
      borderRadius: "6px",
      color: "#131313",
      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.16)",
    },

    scroller: {
      width: "6px",
      thumb: "#898989",
      thumbHover: "rgb(23 191 131 / 48%)",
      track: "transparent",
    },

    panel: {
      backgroundColor: "rgba(54, 54, 54, 0.2)",
      borderColor: "rgba(255, 255, 255, 0.5)",
      color: "#d3d3d3",
      borderRadius: "12px",
      boxShadow: "0 0 20px 0 rgba(255, 255, 255, 0.24)",
    },

    button: {
      backgroundColor: "transparent",
      borderColor: "#858585",
      color: "#434242",
      hoverBackgroundColor: "#fff",
      hoverColor: "#434242",
      hoverBorderColor: "#434242",
      disabledBackgroundColor: "transparent",
      disabledColor: "#adacac",
      disabledBorderColor: "#adacac",
    },

    palette: {
      common: {
        black: "#131313",
        white: "#ffffff",
        gray: "#898989",
      },
      primary: {
        main: "rgb(255 255 255 / 80%)",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#709fb0",
        contrastText: "#ffffff",
      },
    },
  },
};

export const blogDarkTheme: AppTheme = {
  blog: {
    name: "blog_dark_theme",
    backgroundColor: "rgba(19, 19, 19, 0.94)",
    backgroundImage: `url(${sympire_defoult_bg_img})`,
    borderRadius: "8px",
    borderColor: "rgb(23 191 131 / 48%)",
    textColor: "rgb(255 255 255 / 80%)",
    padding: "0.5rem",
    filter: "none",
    header: {
      width: "850px",
      height: "52px",
      backgroundColor: "rgba(19, 19, 19, 0.8)",
      borderRadius: "2px",
      borderColor: "#398a6e",
      boxShadow: "0 2px 12px 0 rgba(73, 189, 149, 0.5)",
      labelColor: "#fff",
      textColor: "#fff",
      separatorColor: "#d3d3d3",
      padding: "0 16px",
      gap: 0,
    },

    content: {
      width: "850px",
      backgroundColor: "#1f1f1f",
      borderRadius: 0,
      borderColor: "rgb(30, 57, 47)",
      boxShadow: "none",
      fontFamily: '"Roboto", sans-serif',
      fontSize: "18px",
      color: "#fff",
      padding: "32px 8px 32px 80px",
      gap: "24px",

      header: {
        fontFamily: '"Outfit", system-ui',
        fontSize: "34px",
        fontWeight: 600,
        color: "#fff",
      },

      title: {
        fontFamily: '"Outfit", system-ui',
        fontSize: "20px",
        fontWeight: 500,
        color: "#fff",
      },

      playerAvatar: {
        borderColor: "rgba(255, 255, 255, 0.8)",
      },

      exam: {
        titleColor: "#131313",
        color: "#131313",
        checkbox: {
          width: "16px",
          height: "16px",
          backgroundColor: "#131313",
          hoverBackgroundColor: "#131313",
          activeBackgroundColor: "#131313",
          disabledBackgroundColor: "#131313",
          borderColor: "#898989",
          hoverBorderColor: "#49bd95",
          activeBorderColor: "#49bd95",
          disabledBorderColor: "#363636",
          borderRadius: "4px",
          color: "#fff",
          //disabledColor: "#363636",
        },
        input: {
          width: "100%",
          height: "48px", //88px
          backgroundColor: "#1f1f1f",
          activeBackgroundColor: "#1f1f1f",
          hoverBackgroundColor: "#1f1f1f",
          disabledBackgroundColor: "#1f1f1f",
          borderColor: "#4d4d4d",
          hoverBorderColor: "#49bd95",
          activeBorderColor: "#49bd95",
          disabledBorderColor: "#363636",
          borderRadius: "8px",
          color: "#898989",
          disabledColor: "#d3d3d3",
        },
        submitButton: {
          width: "80px",
          height: "34px",
          backgroundColor: "#131313",
          hoverBackgroundColor: "#2a2a2a",
          activeBackgroundColor: "#131313",
          disabledBackgroundColor: "#2a2a2a",
          borderColor: "#898989",
          hoverBorderColor: "#898989",
          activeBorderColor: "#49bd95",
          disabledBorderColor: "#4d4d4d",
          borderRadius: "8px",
          color: "#d3d3d3",
          hoverColor: "#d3d3d3",
          activeColor: "#d3d3d3",
          disabledColor: "#898989",
        },
      },
    },

    arsenal: {
      container: {
        maxWidth: "202px",
        minWidth: "149px",
        backgroundColor: "#131313",
        borderRadius: "2px",
        borderColor: "#1e392f",
        color: "#bdbdbd",
      },
      item: {
        width: "100%",
        height: "68px",
        backgroundColor: "#1f1f1f",
        activeBackgroundColor: "rgba(73, 189, 149, 0.2)",
        disabledBackgroundColor: "transparent",
        borderRadius: "2px",
        borderColor: "#4d4d4d",
        hoverBorderColor: "#4d4d4d",
        activeBorderColor: "#49bd95",
        disabledBorderColor: "#363636",
        boxShadow: "0 0 0 0 rgba(255, 255, 255, 0)",
        activeBoxShadow: "0 0 0 0 rgba(255, 255, 255, 0)",
        hoverBoxShadow: "0 0 10px 0 rgba(255, 255, 255, 0.64)",
        color: "#d3d3d3",
        fontSize: "14px",
        fontFamily: `"Roboto Mono", monospace`,
      },
    },

    navbar: {
      container: {
        backgroundColor: "rgb(8, 16, 25)",
        borderRadius: 0,
        borderColor: "rgb(30, 57, 47)",
      },
      navItem: {
        width: "36px",
        height: "36px",
        backgroundColor: "#131313",
        hoverBackgroundColor: "#363636",
        activeBackgroundColor: "#49bd95",
        disabledBackgroundColor: "#fbfbfb",
        examBackgroundColor: "#2a84ff",
        borderColor: "#d3d3d3",
        hoverBorderColor: "#d3d3d3",
        activeBorderColor: "#49bd95",
        disabledBorderColor: "#435461",
        boxShadow: " 0 0 0 0 rgba(255, 255, 255, 0)",
        hoverBoxShadow: "0 0 8px 0 rgba(255, 255, 255, 0.8)",
        activeBoxShadow:
          "0 0 8px 0 rgba(73, 189, 149, 0.8), 0 0 12px 0 rgba(255, 255, 255, 0.2)",
        color: "#d3d3d3",
        hoverColor: "#d3d3d3",
        activeColor: "#fff",
        disabledColor: "#435461",
        labelColor: "#898989",
      },
      arrow: {
        width: "34px",
        height: "34px",
        backgroundColor: "#131313",
        hoverBackgroundColor: "#131313",
        activeBackgroundColor: "#131313",
        disabledBackgroundColor: "#131313",
        borderColor: "#398a6e",
        hoverBorderColor: "#49bd95",
        activeBorderColor: "#49bd95",
        disabledBorderColor: "#4d4d4d",
        color: "#398a6e",
        hoverColor: "#49bd95",
        activeColor: "#49bd95",
        disabledColor: "#4d4d4d",
        hoverBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      },
    },

    networkMap: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      borderColor: "rgba(255, 255, 255, 0.24)",
      borderRadius: "12px",
      boxShadow: "0 0 20px 0 rgba(255, 255, 255, 0.24)",
      titleColor: "#d3d3d3",
      color: "#d3d3d3",
    },

    popup: {
      backgroundColor: "#081019",
      borderColor: "#1e392f",
      borderRadius: "2px",
      boxShadow: "0 0 12px 0 rgba(73, 189, 149, 0.24)",
      titleColor: "#c0cbd5",
      color: "#fff",
    },

    link: {
      color: "#2a84ffbd",
      hoverColor: "#2a84ff",
      activeColor: "#2a84ff",
      disabledColor: "#d3d3d3",
    },

    tooltip: {
      backgroundColor: "rgb(8 16 25 / 41%)",
      borderColor: "rgba(255, 255, 255, 0.24)",
      borderRadius: "6px",
      color: "#fff",
      boxShadow: "0 0 10px 0 rgba(255, 255, 255, 0.24)",
    },

    scroller: {
      width: "6px",
      thumb: "#898989",
      thumbHover: "rgb(23 191 131 / 48%)",
      track: "transparent",
    },

    panel: {
      backgroundColor: "#fff",
      borderColor: "#d3d3d3",
      color: "#363636",
      borderRadius: "12px",
      boxShadow: "0 0 5px 2px rgba(49, 49, 64, 0.05)",
    },

    button: {
      backgroundColor: "transparent",
      borderColor: "rgba(255,255,255,0.5)",
      color: "rgba(255,255,255,0.8)",
      hoverBackgroundColor: "rgba(255,255,255,0.1)",
      hoverColor: "#fff",
      hoverBorderColor: "rgba(255,255,255,0.7)",
      disabledBackgroundColor: "transparent",
      disabledColor: "rgba(255,255,255,0.25)",
      disabledBorderColor: "rgba(255,255,255,0.25)",
    },

    palette: {
      common: {
        black: "#131313",
        white: "#ffffff",
        gray: "#898989",
      },
      primary: {
        main: "rgb(255 255 255 / 80%)",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#709fb0",
        contrastText: "#ffffff",
      },
    },
  },
};

const playerDarkTheme: AppTheme = {
  player: {
    name: "player_dark_theme",
    backgroundColor: "rgba(28, 29, 31, 0.5)",
    borderColor: "#1e392f",

    content: {
      backgroundColor: "#08040d",
      borderRadius: 0,
    },

    header: {
      width: "1134px",
      height: "52px",
      backgroundColor: "#131313",
      borderRadius: "2px",
      borderColor: "#398a6e",
      boxShadow: "0 2px 12px 0 rgba(73, 189, 149, 0.5)",
      labelColor: "#fff",
      textColor: "#fff",
      separatorColor: "#d3d3d3",
      padding: "0 16px",
      gap: 0,
    },
    // scroller: {
    //   width: "4px",
    //   thumb: "#1e392f",
    //   thumbHover: "rgb(73 189 149 / 40%)",
    //   track: "transparent",
    // },
  },
};

// export const blueTheme: DefaultTheme = {
//   ...defaultTheme,
//   name: "eternal_blue",
//   backgroundColor: "#213849",
//   backgroundImage: `url(${blue_bg_img})`,
//   borderColor: "#213e51",
//   player: {
//     backgroundColor: "rgba(28, 29, 31, 0.5)",
//     borderRadius: "0",
//     borderColor: "#213849",
//     boxShadow: "transparent",
//     gap: "12px", //"0.75rem", //12px //the gap between general panels
//     header: {
//       backgroundColor: "rgba(28, 29, 31, 0.5)",
//       borderRadius: "0.75rem", //12px
//       borderColor: "transparent",
//     },s
//     content: {
//       backgroundColor: "rgba(28, 29, 31, 0.5)",
//       borderRadius: "0.75rem", //12px
//       fontFamily: "Outfit",
//       borderColor: "transparent",
//     },
//     arsenal: {
//       backgroundColor: "rgba(28, 29, 31, 0.5)",
//       borderRadius: "0.75rem", //12px
//       borderColor: "transparent",
//     },
//     terminal: {
//       backgroundColor: "rgba(28,29,31,0.8)",
//       borderRadius: "0.75rem", //12px
//       borderColor: "transparent",
//     },
//     scroller: {
//       thumb: "rgba(255, 255, 255, 0.1)",
//       thumbHover: "#284a60",
//       track: "transparent",
//     },
//   },
//   panel: {
//     primary: "rgba(28, 29, 31, 0.5)",
//     secondary: "#1f2b35",
//     border: "#213e51",
//     borderRadius: "6px",
//     boxShadow: "#233d4f",
//   },
//   scroller: {
//     thumb: "rgba(255, 255, 255, 0.1)",
//     thumbHover: "#213e51",
//     track: "transparent",
//   },
//   palette: {
//     common: {
//       black: "#131313",
//       white: "#ffffff",
//       gray: "#898989",
//     },
//     primary: {
//       main: "#726a95",
//       contrastText: "#ffffff",
//     },
//     secondary: {
//       main: "#709fb0",
//       contrastText: "#ffffff",
//     },
//   },
// };
